<template>
  <div>
    <section v-if="layoutInternalCreated" id="about" class="section-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h2>Proteção solar, decoração e privacidade</h2>

            <h3>Película Profissional Refletiva (PR)</h3>

            <p>
              Refletem a energia solar de 22 à 57% e luz visível de 12 à 60%,
              transmitem energia solar de 9 à 37% e luz visível em até 7 à 47%,
              bloqueiam 99% de raios U.V. (ultra-violeta), mantendo na
              temperatura ambiente agradável, oferecendo alto nível de
              privacidade com grande variedade de cores e tonalidades.
            </p>

            <p>
              A Película (PR) é indicada para ambientes comerciais como hotéis,
              escritórios, repartições públicas, escolas, residências, casas,
              apartamentos, prédios, empresas ou qualquer ambiente que
              necessitem das características descritas acima.
            </p>

            <p>
              Usamos Película Profissional Refletiva (PR) da marca InterControl
              e 3M que são popularmente conhecidas como Insulfilm.
            </p>

            <h3>Película Profissional Refletivo Externo (PEX)</h3>

            <p>
              Refletem 62% de energia solar e 60% de luz visível, transmitem 15%
              de energia solar e 15% de luz visível, possuem as mesmas
              características do refletivo descritas acima, mas só disponível na
              cor prata.
            </p>

            <p>
              Sua diferença é que essa película prata fora projetada para se
              aplicar pelo lado externo do ambiente, indicado para ambientes
              inacessíveis internamente.
            </p>

            <p>
              Usamos Película Profissional Refletivo Externo (PEX) da marca
              InterControl e 3M. Estas películas também são chamadas pela marca
              Insulfilm.
            </p>

            <h3>Película Privacidade</h3>

            <p>
              As Películas Privacidade (também conhecidas como Insulfilm)
              refletem até 79% da energia solar, e transmitem até 20% de luz
              visível, bloqueando até 1% dos raios U. V., tendo como objetivo
              privatizar ambientes.
            </p>

            <h3>Película Profissional de Alta Performance (PAP)</h3>

            <p>
              Refletem energia solar de 10 à 48%, luz visível de 11 à 38%,
              transmitem energia solar de 13 à 60% e luz visível de 19 à 48%,
              bloqueiam 99% de raios U.V.
            </p>

            <p>
              Estas Películas (Insulfilm) mantém o ambiente em temperatura
              agradável, mas com baixo nível de privatização e com cores bronze
              e neutra, indicados também para hotéis, escritórios, repartições
              públicas, escolas, residências, casas, apartamentos, prédios,
              empresas ou seja podem ser utilizados em qualquer ambiente desde
              que não necessitem de privatizar o ambiente interno.
            </p>

            <h3>Película Profissional Semi-Refletivo (PD)</h3>

            <p>
              Refletem energia solar de 15 à 18%, luz visível de 9 à 15%,
              transmitem energia solar de 24 à 32%, luz visível de 15 à 30%,
              bloqueiam 99% de raios U.V.
            </p>

            <p>
              Mantém o ambiente em temperatura agradável, mas com baixo nível de
              privatização e com cores bronze, cinza e verde, indicados também
              para hotéis, escritórios, repartições públicas, escolas,
              residências, casas, apartamentos ou seja podem ser utilizados em
              qualquer ambiente desde que não necessitem de privatizar o
              ambiente interno.
            </p>

            <p>
              Usamos Película Profissional Semi-Refletivo (PD) da marca
              InterControl e 3M. Estas películas também são chamadas pela marca
              Insulfilm.
            </p>

            <h3>Película Profissional Museu (PUV)</h3>

            <p>
              Refletem energia solar em e luz visível em 9%, transmitem energia
              solar e luz visível em 84%, bloqueiam 99% de raios U. V.
            </p>

            <p>
              Película incolor que protege objetos e artigos dos raios nocivos
              do sol ideal para ambientes que necessitem de proteção sem tirar
              sua visibilidade, ou seja conservando suas propriedades óticas.
              Estas películas transparentes (incolores) são indicados para
              vidros e vitrines de comércios de roupas, vitrines de artigos de
              couro, móveis, obras de arte, residências (cozinhas, salas,
              quartos), janelas de empresas, janelas de escritórios.
            </p>

            <p>
              Usamos Película Profissional Museu (PUV) da marca InterControl e
              3M que são popularmente conhecidas pela marca Insulfilm.
            </p>

            <h3>Película Profissional de Segurança (PS)</h3>

            <p>
              As películas de segurança incolores refletem energia solar de 8 à
              10%, luz visível de 9 à 12%, transmitem energia solar de 85 à 88%,
              luz visível de 87 à 90%, bloqueiam 95% de raios U. V.
            </p>

            <p>
              Possuem as mesmas características da película museu mas com uma
              pequena diferença no nível de proteção contra os raios U.V. de 5%,
              só que com a vantagem de se colocados em moldura permite que o
              vidro absorva choque de até 1800 Kg/força.
            </p>

            <p>
              Protege vidros e janelas de pedradas, pauladas, pancadas e
              impactos. Esta película de segurança também é indicada para
              vitrines de comércios de roupas, artigos de couro, móveis, obras
              de arte, guaritas, postos de segurança e repartições públicas,
              supermercados, padarias, hotéis, escritórios, bancos, residências
              (cozinhas, salas, quartos).
            </p>

            <p>
              Os películas coloridas refletem energia solar de 7 à 55%, luz
              visível de 6 à 60%, transmitem energia solar de 12 à 52%, luz
              visível de 15 à 48%, bloqueiam 99% de raios U.V.
            </p>

            <p>
              Estas películas de segurança coloridas possuem as mesmas
              características da película de segurança incolor mas com a
              vantagem de proporcionar privacidade ao ambiente. São indicadas
              para postos, departamentos de segurança, repartições públicas,
              guaritas ou portaria de condomínios, clubes, casas noturnas,
              bingos, ambientes de empresas.
            </p>
          </div>
          <!-- col end -->

          <div class="col-lg-5 section-content-illustration">
            <QuotationCall position="float" />
          </div>
          <!-- col end -->
        </div>
        <!-- row end -->
      </div>
    </section>
  </div>
</template>

<script>
import LayoutInternal from "@/layouts/LayoutInternal.vue";
import { layoutInternalCreated } from "@/mixin/layout-internal-created-mixin.js";

const QuotationCall = () =>
  import(
    /* webpackChunkName: "QuotationCall" */ "@/components/cotacao/quotation-call.vue"
  );

export default {
  mixins: [layoutInternalCreated],

  components: {
    QuotationCall,
  },

  created() {
    this.$emit(`update:layout`, LayoutInternal);
    this.$emit(`update:title`, "Películas InterControl");
    this.$emit(
      `update:introduction`,
      "Instalamos as películas InterControl em comércios, sacadas, escritórios, apartamentos, divisórias, portarias e na arquitetura em geral"
    );
    this.$emit(`update:bgHeader`, "black");
  },
};
</script>

